import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Paper, Button, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react'; // Asegúrate de importar el hook
import { useState, useEffect } from 'react'; // Importar useState y useEffect
import * as ExcelJS from 'exceljs'; // Importar la biblioteca exceljs
import * as FileSaver from 'file-saver'; // Importar file-saver para exportar a CSV
import { styled } from '@mui/material/styles';
import './TablaUsuarios.css';

function hexToArgb(hex) {
    // Asegúrate de que el hex tenga el formato correcto
    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }
    if (hex.length !== 6) {
        throw new Error('Formato de color hexadecimal no válido');
    }
    return `FF${hex.toUpperCase()}`; // Agregar 'FF' para el canal alfa
}

const TablaUsuarios = () => {
    const supabaseClient = useSupabaseClient();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadStatus, setUploadStatus] = useState(null); // Estado para carga masiva
    const [singleUserUploadStatus, setSingleUserUploadStatus] = useState(null); // Estado para creación de un solo usuario
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [newUser, setNewUser] = useState({
        nombre: '',
        apellido: '',
        email: ''
    });
    const [message, setMessage] = useState(''); // Estado para manejar mensajes de creación individual
    const [isCreating, setIsCreating] = useState(false); // Estado para manejar el proceso de creación

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const { data, error } = await supabaseClient
                .from('usuarios')
                .select('*');

            if (error) throw error;

            // Obtener los registros de graduados para cada usuario
            const usersWithGraduationData = await Promise.all(data.map(async (user) => {
                const { data: graduationData, error: graduationError } = await supabaseClient
                    .from('graduados')
                    .select('idcurso, modulo, porcentaje, created_at') // Asegúrate de incluir 'created_at'
                    .eq('iduser', user.iduser)
                    .order('created_at', { ascending: true });
                    

                if (graduationError) {
                    console.error('Error al cargar los datos de graduados:', graduationError);
                    return { ...user, cursos: [] }; // Manejo de error
                }

                // Mapear los datos de graduación a un formato más útil
                const cursos = graduationData.map(graduation => {
                    const puntajeFinal = graduation.porcentaje 
                        ? Math.floor(parseFloat(graduation.porcentaje.replace(',', '.'))) === 99 
                            ? 100 
                            : Math.floor(parseFloat(graduation.porcentaje.replace(',', '.'))) 
                        : 0;

                    return {
                        idcurso: graduation.idcurso,
                        modulo: graduation.modulo,
                        estado: puntajeFinal >= 99 ? 'Aprobado' : 'Reprobado',
                        porcentaje: puntajeFinal
                    };
                });

                return { ...user, cursos }; // Agregar los cursos al usuario
            }));

            setUsers(usersWithGraduationData);
        } catch (error) {
            console.error('Error al cargar los usuarios:', error);
            setUploadStatus('Error al cargar los usuarios');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [supabaseClient]);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            setUploadStatus('Cargando usuarios...');
            const response = await fetch('/.netlify/functions/createUsers', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Error en la respuesta del servidor');
            }

            const result = await response.json();

            if (result.error) {
                throw new Error(result.error);
            }

            setUploadStatus('Usuarios cargados correctamente');
            fetchUsers(); // Actualizar la lista de usuarios
        } catch (error) {
            console.error('Error:', error);
            setUploadStatus(`Error al cargar el archivo: ${error.message}`);
        }
    };

    const handleViewProfile = (userId) => {
        navigate(`/usuario-perfil/${userId}`);
    };

    const filteredUsers = users.filter(user => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            user.nombre.toLowerCase().includes(searchTermLower) ||
            user.apellido.toLowerCase().includes(searchTermLower) ||
            user.email.toLowerCase().includes(searchTermLower)
        );
    });

    const columns = [
        { field: 'nombre', headerName: 'Nombre', flex: 1 }, // Usar flex en lugar de width
        { field: 'apellido', headerName: 'Apellido', flex: 1 }, // Usar flex en lugar de width
        { 
            field: 'email', 
            headerName: 'Email', 
            flex: 2, // Usar flex para ajustar automáticamente
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.2em' }}>
                    {params.value}
                </div>
            )
        },
        { field: 'clave', headerName: 'Clave', flex: 1 }, // Usar flex en lugar de width
    ];

    if (loading) return <p>Cargando usuarios...</p>;

    const visibleRows = filteredUsers.slice(0, paginationModel.pageSize); // Obtener solo las filas visibles

    const exportVisibleToExcel = () => {
        const visibleRows = filteredUsers.slice(0, paginationModel.pageSize);

        if (visibleRows.length === 0) return;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Usuarios');

        // Agregar encabezados dinámicos
        const headers = [
            { header: 'Nombre', key: 'nombre', width: 20 },
            { header: 'Apellido', key: 'apellido', width: 20 },
            { header: 'Email', key: 'email', width: 30 },
            { header: 'Clave', key: 'clave', width: 15 },
            { header: 'Link', key: 'link', width: 70 },
        ];

        // Crear un conjunto para almacenar encabezados únicos
        const uniqueHeaders = new Set();

        // Agregar encabezados para cada curso y módulo
        visibleRows.forEach(user => {
            user.cursos.forEach(curso => {
                const header = `${curso.idcurso} - ${curso.modulo}`;
                uniqueHeaders.add(header);
            });
        });

        // Convertir el conjunto a un array y agregarlo a los headers
        Array.from(uniqueHeaders).forEach(header => {
            headers.push({ header, key: header.replace(/ /g, '_'), width: 20 });
        });

        worksheet.columns = headers;

        // Agregar filas visibles
        visibleRows.forEach(user => {
            const row = {
                nombre: user.nombre,
                apellido: user.apellido,
                email: user.email,
                clave: user.clave,
                link: { text: `https://miesvr.cl/usuario-perfil/${user.iduser}`, hyperlink: `https://miesvr.cl/usuario-perfil/${user.iduser}` },
            };

            user.cursos.forEach(curso => {
                const porcentajeKey = `${curso.idcurso} - ${curso.modulo}`.replace(/ /g, '_');
                row[porcentajeKey] = curso.porcentaje ? parseFloat(curso.porcentaje) / 100 : 0; // Convertir a número
            });

            const newRow = worksheet.addRow(row);

            // Centrar y aplicar formato condicional al porcentaje de cada curso
            user.cursos.forEach(curso => {
                const porcentajeKey = `${curso.idcurso} - ${curso.modulo}`.replace(/ /g, '_');
                const cell = newRow.getCell(porcentajeKey);
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.numFmt = '0%'; // Formato de porcentaje

                // Aplicar formato condicional
                if (curso.porcentaje >= 99) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: hexToArgb('#C6EFCE') } // Convertir fondo verde a ARGB
                    };
                    cell.font = {
                        color: { argb: hexToArgb('#006100') } // Convertir texto verde a ARGB
                    };
                } else {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: hexToArgb('#FFC7CE') } // Convertir fondo rojo a ARGB
                    };
                    cell.font = {
                        color: { argb: hexToArgb('#9C0006') } // Convertir texto rojo a ARGB
                    };
                }
            });
        });

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            const date = new Date();
            const formattedDate = `${date.getDate()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
            a.href = url;
            a.download = `usuarios-${formattedDate}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    // Función para manejar el cambio en los campos del formulario
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser({
            ...newUser,
            [name]: value
        });
    };

    const handleCreateUser = async () => {
        setIsCreating(true); // Iniciar el estado de creación
        try {
            // Validaciones en orden
            if (newUser.nombre.trim() === '') {
                setMessage('El nombre es obligatorio'); // Mensaje de error para nombre vacío
                setIsCreating(false);
                return;
            }
            if (newUser.nombre.length < 3 || !/^[a-zA-Z\s]+$/.test(newUser.nombre)) {
                setMessage('El nombre debe tener más de 3 caracteres y solo contener texto'); // Mensaje de error para nombre
                setIsCreating(false);
                return;
            }
            if (newUser.apellido.trim() === '') {
                setMessage('El apellido es obligatorio'); // Mensaje de error para apellido vacío
                setIsCreating(false);
                return;
            }
            if (newUser.apellido.length < 3 || !/^[a-zA-Z\s]+$/.test(newUser.apellido)) {
                setMessage('El apellido debe tener más de 3 caracteres y solo contener texto'); // Mensaje de error para apellido
                setIsCreating(false);
                return;
            }
            if (!validateEmail(newUser.email)) {
                setMessage('El email no es válido'); // Mensaje de error para email
                setIsCreating(false);
                return;
            }

            setSingleUserUploadStatus('Creando usuario...'); // Actualiza el estado para creación de un solo usuario
            const response = await fetch('/.netlify/functions/createSingleUser', {
                method: 'POST',
                body: JSON.stringify(newUser),
            });

            if (!response.ok) {
                const errorResult = await response.json();
                throw new Error(errorResult.error || 'Error en la respuesta del servidor');
            }

            const result = await response.json();

            // Manejo específico para el error de email existente
            if (result.error && result.error.includes('usuario ya existe')) {
                setMessage('El email ya está en uso'); // Mensaje de error específico
                setIsCreating(false);
                return;
            }

            setMessage('Usuario creado correctamente'); // Mensaje de éxito
            setOpenDialog(false);
            
            // Limpiar los campos del formulario
            setNewUser({
                nombre: '',
                apellido: '',
                email: ''
            });

            // Limpiar el mensaje después de 3 segundos
            setTimeout(() => {
                setMessage('');
            }, 3000);

            fetchUsers(); // Actualizar la lista de usuarios
        } catch (error) {
            console.error('Error:', error);
            setMessage(`Error al crear el usuario: ${error.message}`);
        } finally {
            setIsCreating(false); // Finalizar el estado de creación
        }
    };

    // Función para validar el email
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // Limpiar el mensaje al abrir el diálogo
    const handleOpenDialog = () => {
        setOpenDialog(true);
        setMessage(''); // Limpiar el mensaje al abrir el diálogo
    };

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', // Cambiar a columna para alinear verticalmente
                alignItems: 'center', 
                height: '100vh',
                margin: '40px auto',
            }}
        >
            <Box sx={{ width: '100%', maxWidth: 800, mb: 2, display: 'flex' }}>
                <TextField
                    sx={{ width: '70%' }}
                    variant="outlined"
                    size="small"
                    placeholder="Buscar por nombre, apellido o email..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <span className="material-symbols-outlined">search</span>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    sx={{ width: '30%', ml: 2 }}
                    variant="contained"
                    onClick={handleOpenDialog} // Cambiar a la nueva función
                    startIcon={<span className="material-symbols-outlined">person_add</span>}
                >
                    Nuevo Usuario
                </Button>
            </Box>
            <Paper sx={{ height: 400, width: '100%', maxWidth: 800 }}>
                <DataGrid
                    rows={filteredUsers}
                    columns={columns}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 20, 50]}
                    getRowId={(row) => row.id}
                    onRowClick={(params) => handleViewProfile(params.row.iduser)}
                    sx={{ 
                        border: 0,
                        '& .MuiDataGrid-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeader': {
                            padding: '0',
                        },
                    }}
                    disableColumnMenu
                    disableColumnSelector
                />
            </Paper>
            <span className="material-symbols-outlined pulse-icon mt-10">
                stat_minus_2
            </span> 
            <Paper sx={{ height: 400, width: '100%', maxWidth: 800, mb: 2, mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ padding: 2, textAlign: 'center' }}>
                    <h3 className='font-bold'>Descarga de Usuarios</h3>
                    <p>Puedes descargar la base de datos de usuarios en formato Excel o CSV:</p>
                    <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                        <Button 
                            variant="contained" 
                            onClick={exportVisibleToExcel} 
                            disabled={filteredUsers.length === 0} // Desactivar si no hay filas visibles
                        >
                            Exportar a Excel
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Paper sx={{ height: 400, width: '100%', maxWidth: 800, mb: 2, mt: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <h3 className='font-bold'>Carga Masiva de Usuarios</h3>
                    <p>Para cargar usuarios masivamente, descarga la plantilla y luego haz clic en el botón "Cargar Usuarios".</p>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: 2 }}>
                        <Button 
                            variant="contained" 
                            onClick={() => {
                                // Lógica para descargar plantilla
                                const url = 'https://ctnhximzoqijdhdunfhn.supabase.co/storage/v1/object/public/mies/plantilla-para-cargar-usuarios.xlsx?t=2024-10-11T01%3A28%3A53.775Z';
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = 'plantilla-para-cargar-usuarios.xlsx'; // Nombre del archivo al descargar
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                            }}
                        >
                            Descargar Plantilla
                        </Button>
                        <Button
                            component="label"
                            variant="contained"
                        >
                            Cargar Usuarios
                            <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept=".xlsx,.csv" />
                        </Button>
                    </Box>
                </Box>
            </Paper>
            {uploadStatus && (
                <Box sx={{ marginTop: '10px', textAlign: 'center' }}>
                    <p>{uploadStatus}</p>
                </Box>
            )}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Crear Nuevo Usuario</DialogTitle>
                <DialogContent>
                    <TextField
                        name="nombre"
                        value={newUser.nombre}
                        onChange={handleInputChange}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="apellido"
                        value={newUser.apellido}
                        onChange={handleInputChange}
                        label="Apellido"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        name="email"
                        value={newUser.email}
                        onChange={handleInputChange}
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    {/* Separator for training invitation */}
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px 0' }}>
                        <hr style={{ flex: 1, border: '1px solid #ccc' }} />
                        <h4 style={{ margin: '0 10px' }}>Invitación Capacitación</h4>
                        <hr style={{ flex: 1, border: '1px solid #ccc' }} />
                    </div>
                    <TextField
                        name="fechaCapacitacion"
                        value={newUser.fechaCapacitacion}
                        onChange={handleInputChange}
                        
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="date" // Tipo de campo para fecha
                    />
                    <TextField
                        name="horaCapacitacion"
                        value={newUser.horaCapacitacion}
                        onChange={handleInputChange}
                        
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="time" // Tipo de campo para hora
                    />
                    <TextField
                        name="lugarCapacitacion"
                        value={newUser.lugarCapacitacion}
                        onChange={handleInputChange}
                        label="Lugar Capacitación"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    {message && <p style={{ color: 'red' }}>{message}</p>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={handleCreateUser} disabled={isCreating}>
                        {isCreating ? 'Creando...' : 'Crear'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TablaUsuarios;