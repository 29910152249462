import React, { useState, useEffect } from 'react';
import { useUser } from '@supabase/auth-helpers-react';
import { supabase } from './../lib/supabaseClient';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import './Dashboard.css';
import TablaUsuarios from '../objetos/TablaUsuarios';
import Footer from '../objetos/Footer';
import Box from '@mui/material/Box'; // Agregado para CenteredTabs
import Tabs from '@mui/material/Tabs'; // Agregado para CenteredTabs
import Tab from '@mui/material/Tab'; // Agregado para CenteredTabs
import { styled } from '@mui/material/styles';
import { LinearProgress, linearProgressClasses } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#e0e0e0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: (() => {
      if (value < 34) return '#FF0000';
      if (value < 70) return '#FFD700';
      return '#00FF00';
    })(),
  },
}));

const Dashboard = () => {
  const userContext = useUser();
  const [userInfo, setUserInfo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [cursos, setCursos] = useState([]);
  const [isSortedAsc, setIsSortedAsc] = useState(true); // Nuevo estado para controlar el orden
  const navigate = useNavigate(); // Inicializa useNavigate
  const [tabValue, setTabValue] = useState(0); // Estado para las pestañas
  const [cursosProgreso, setCursosProgreso] = useState({});
  const [usuariosPorCurso, setUsuariosPorCurso] = useState({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Actualiza el valor de la pestaña
  };

  useEffect(() => {
    async function fetchUserInfo() {
      if (userContext) {
        try {
          const { data, error, count } = await supabase
            .from('usuarios')
            .select('nombre, apellido', { count: 'exact' })
            .eq('email', userContext.email);

          if (error) {
            throw error;
          }

          if (count === 1) {
            setUserInfo(data[0]);
          } else {
            console.error('Error: Se encontraron múltiples usuarios con el mismo correo electrónico o ninguno.');
          }
        } catch (error) {
          console.error('Error al cargar la información del usuario:', error.message);
        }
      }
    }

    fetchUserInfo();
  }, [userContext]);


  useEffect(() => {
    async function fetchCursosProgreso() {
      try {
        const { data, error } = await supabase.rpc('get_cursos_progreso');

        if (error) throw error;

        console.log('Datos recibidos:', data); // Verifica que los datos se cargan correctamente

        const progresoPorCursoModulo = {};
        const usuariosPorCursoModulo = {};

        data.forEach(registro => {
          const key = `${registro.idcurso}-${registro.modulo}`;
          progresoPorCursoModulo[key] = registro.promedio_porcentaje;
          usuariosPorCursoModulo[key] = registro.usuarios_unicos;
        });

        console.log('Progreso por curso:', progresoPorCursoModulo); // Verifica el estado de progreso
        console.log('Usuarios por curso:', usuariosPorCursoModulo); // Verifica el estado de usuarios

        setCursos(data); // Usa los datos directamente
        setCursosProgreso(progresoPorCursoModulo);
        setUsuariosPorCurso(usuariosPorCursoModulo);
      } catch (error) {
        console.error('Error al cargar el progreso de los cursos:', error.message);
      }
    }

    fetchCursosProgreso();
  }, []); // Asegúrate de que el array de dependencias esté vacío para que se ejecute solo una vez

  // const filteredCursos = cursos.filter(curso =>
  //   curso.curso.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   curso.idcurso.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const handleSortByIdCurso = () => {
    const sortedCursos = [...cursos].reverse(); // Invertir el orden actual
    setCursos(sortedCursos);
    setIsSortedAsc(!isSortedAsc); // Alternar el estado de orden
  };

  return (
    <div className="container mt-10">

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          centered 
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#2E2E2E', // Cambia el color del subrayado
            },
            '& .Mui-selected': {
              color: '#2E2E2E', // Cambia el color del tab activo
            },
          }}
        >
          <Tab label="Usuarios" />
          <Tab label="Cursos" />
        </Tabs>
      </Box>

      {tabValue === 0 && (
        <TablaUsuarios />
      )}
      {tabValue === 1 && ( // Mostrar el componente UserTable en la pestaña "Usuarios"
         <>
         {/* <div className="search-container d-flex justify-content-end">
           <div className="search-bar d-flex align-items-center">
             <input 
               type="text" 
               placeholder="BUSCAR CURSO" 
               value={searchTerm} 
               onChange={(e) => setSearchTerm(e.target.value)} 
             />
             <button type="button" className="search-button">
               <span className="icon-button-order" onClick={handleSortByIdCurso}>
                 <span className="material-symbols-outlined">
                   sort_by_alpha
                 </span>
               </span>
             </button>
           </div>
         </div> */}

         <div className="tarjetas-container">
           {cursos.map(curso => {
             console.log('Curso:', curso); // Verifica la estructura del objeto curso
             const key = `${curso.idcurso}-${curso.modulo}`; // Asegúrate de que los nombres coincidan
             console.log('Renderizando tarjeta para:', key); // Verifica que las tarjetas se están renderizando
             return (
               <div 
                 className="card" 
                 key={key}
                 onClick={() => navigate(`/dashboard/${curso.idcurso}/${curso.url}`)} // Navegación al hacer clic
                 style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clicable
               >
                 <h2 className='idcurso' style={{ color: curso.color }}>{curso.idcurso}</h2>
                 <h3>{curso.modulo}</h3>
                 <figure className="text-center">
                   <img src={curso.url_portada} alt={`Imagen del curso ${curso.curso}`} />
                 </figure>
                 <div className="card-body">
                   <h3>{curso.curso}</h3>
                   <div style={{ marginTop: '30px' }}>
                     <BorderLinearProgress 
                       variant="determinate" 
                       value={cursosProgreso[key] || 0}
                     />
                     <p style={{ 
                       textAlign: 'center', 
                       marginTop: '10px', 
                       fontSize: '20px', 
                       fontWeight: 'bold',
                       color: (() => {
                         const value = cursosProgreso[key] || 0;
                         if (value < 34) return '#FF0000';
                         if (value < 70) return '#FFD700';
                         return '#00FF00';
                       })()
                     }}>
                       {cursosProgreso[key] || 0}%
                     </p>
                   </div>
                   <div style={{ 
                     display: 'flex', 
                     alignItems: 'center', 
                     justifyContent: 'flex-start', 
                     marginTop: '30px', 
                     paddingTop: '10px',
                     fontSize: '18px',
                   }}>
                     <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                       group
                     </span>
                     <span>{usuariosPorCurso[key] || 0} graduados</span>
                   </div>
                 </div>
               </div>
             );
           })}
         </div>
       </>
      )}

      <Footer />
    </div>
  );
};

export default Dashboard;
